<template>
    <div>
        <v-container fluid class="pa-6">
            <DatatableV2 :displayTitle="false" :flat="false" @openLibraryDialog="openLibraryDialog" :url="apiUrl" :key="currentKey" ref="datatableOpe" @openTeamAttributionPopup="openTeamAttributionPopup" @dialogHours="dialogHoursPopup"/>
            <LibraryDialogComponent v-if="formLib" :form="formLib" :libType="libType" @cancel="formLib = null;" :showLibraryDialog="showLibraryDialog" @hideLibraryDialog="showLibraryDialog = false;" />
            <teamAttributionPopup v-if="operationId" :operation="operation" :operationId="operationId" @close="closeTeamAttribution" :openTeamAttribution="openTeamAttribution" />
            <v-dialog
                v-model="dialogHours"
                fullscreen
                transition="dialog-bottom-transition"
                scrollable
                persistent
                @keydown.esc="cancel"
                content-class="vdialogPlanning"
                >
                <v-card tile>
                <v-toolbar flat dark class="bg-gradient">
                        <v-btn
                            depressed
                            text
                            color="white"
                            class="square transparent mr-2"
                            @click.native="cancel"
                        >
                            <v-icon dark small>$prev</v-icon>
                        </v-btn>

                    <v-toolbar-title>{{ $t('operationHours') }}</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-btn outlined class="mr-3" @click="cancel" color="white">Annuler</v-btn>
                    <v-btn outlined color="white" @click="recordHours">Enregistrer</v-btn>
                </v-toolbar>
                <v-card-text>
                    <Planning ref="planning" type="OPERATION_HOURS"
                        :genericUnavailable="genericUnavailable2"
                        :isMine="false"
                        :creneau="60"
                        :operations="operations"
                        :appointements="[]"
                        :entityId="entityId"
                        :operationId="operationId"
                        @addItem="addItem"
                        @removeUnavailable="removeUnavailable"
                        @removeAllUnavailable="removeAllUnavailable"
                        @addAllUnavailable="addAllUnavailable"
                        mode="popup"
                        />
                </v-card-text>
                </v-card>
            </v-dialog>
        </v-container>
    </div>
</template>

<script>
import GenericDataService from '@/services/GenericDataService';
import DatatableV2 from '@/components/DatatableV2';
import LibraryDialogComponent from "@/components/dialogs/LibraryDialogComponent";
import teamAttributionPopup from "../operationsComponents/teamAttributionPopup";
import Planning from '@/components/planning/Planning'
import dateManipulation from '@/mixins/dateManipulation'
import planningMixins from '@/mixins/planningMixins'

export default {
    name: 'OperationsList',
    components: { DatatableV2, LibraryDialogComponent, teamAttributionPopup, Planning},
    mixins:[dateManipulation, planningMixins],
    data() {
        return {
            apiUrl:"/operation/list",
            currentKey:0,
            formLib: null,
            libType: "OPERATIONTYPE",
            showLibraryDialog: false,
            openTeamAttribution: false,
            planningKey:0,
            operationId: null,
            operation: null,
            dialogHours: false,
            genericUnavailable2: [],
            operations: [],
            entityId: null,
            hoursReversed: [],
            daysdate:null,
            loading: true,
        }
    },
    created() {
        console.log(this.$route);
    },
    methods: {
        openLibraryDialog() {
            GenericDataService.getData("/library/getFilters?libType=" + this.libType).then((response) => {
                this.formLib = response.data.data;
                this.showLibraryDialog = true;
            });
        },
        openTeamAttributionPopup(object){
            this.operationId = object
            GenericDataService.getData('/operation/'+ object + '/get').then((response) => {
                this.operation = response.data.data
            });

            this.openTeamAttribution = true;
        },
        dialogHoursPopup(object) {
            this.operationId = object

            this.operations = []
            GenericDataService.getData('/operation/'+ object + '/get').then((response) => {
                this.operations = [response.data.data]

                this.operation = response.data.data
                this.entityId = response.data.data.config.entity_id[0]

                GenericDataService.getData('/operation/'+object+'/getHours').then((response) => {
                    this.hoursReversed = response.data.data.hours_text2
                    this.genericUnavailable2 = response.data.data.hours
                    this.dialogHours = true
                    this.loading = false
                }).catch((e) => {
                    this.loading = false
                })
            })
            

        },
        closeTeamAttribution(){
            this.planningKey++
            this.openTeamAttribution = false
        },
        cancel: function(){
            GenericDataService.getData('/operation/'+this.operationId+'/getHours').then((response) => {
                this.hoursReversed = response.data.data.hours_text2
                this.genericUnavailable2 = response.data.data.hours
                this.dialogHours = false
            })
        },
        recordHours(){
            GenericDataService.postData('/operation/'+this.operationId+'/setHours', this.genericUnavailable2).then((response) =>{
                GenericDataService.getData('/operation/'+this.operationId+'/getHours').then((response) => {
                    this.hoursReversed = response.data.data.hours_text2
                    this.genericUnavailable2 = response.data.data.hours
                    this.genericUnavailable = this.$func.hasRight('lead/update_duration') ? [] : response.data.data.hours_reversed
                    this.dialogHours = false
                })
            })
        },
        addItem:function(event, unavailableData) {
           //let slot = this.genericUnavailable.find((e) => e.day == unavailableData.day)
            let item = this._.cloneDeep(unavailableData);
            item.start = this.parseFloatToHour(this.parseHourToFloat(unavailableData.start))
            item.end = this.parseFloatToHour(this.parseHourToFloat(unavailableData.end))
            //check si une indispo est présente avant et apres
            let itemBefore = this.genericUnavailable2.find(
                (e) => (e.day == unavailableData.day &&
                          (
                              this.parseHourToFloat(e.end) == this.parseHourToFloat(item.start) ||
                              (this.parseHourToFloat(e.start) < this.parseHourToFloat(item.start)
                                  && this.parseHourToFloat(item.start) <= this.parseHourToFloat(e.end)
                                  && this.parseHourToFloat(item.end) > this.parseHourToFloat(e.end))
                          )
                        )
            );
            let itemAfter = this.genericUnavailable2.find(
              (e) => (e.day == unavailableData.day &&
                        (
                            this.parseHourToFloat(e.start) == this.parseHourToFloat(item.end) ||
                            (this.parseHourToFloat(item.start) < this.parseHourToFloat(e.start)
                              && this.parseHourToFloat(item.end) >= this.parseHourToFloat(e.start)
                              && this.parseHourToFloat(e.end) > this.parseHourToFloat(item.end))
                        )
                      )
                );
            let itemUnder = this.genericUnavailable2.filter((e) => (e.day == unavailableData.day && this.parseHourToFloat(e.start) >= this.parseHourToFloat(item.start) && this.parseHourToFloat(e.end) <= this.parseHourToFloat(item.end) && e.end != "00:00"))
            // //si les deux existent on redéfini les horaires du premier et on supprime le deuxieme
            if(itemBefore && itemAfter){
                let itemAfterIndex = this.genericUnavailable2.findIndex((e) => e.day == unavailableData.day && e.start == item.end);
                itemBefore.end = itemAfter.end
                this.genericUnavailable2.splice(itemAfterIndex, 1)
            }else if(itemBefore){   // si un element est présent avant alors on le fusionne avec les nouvelles data
                itemBefore.end = item.end
            } else if (itemAfter) { // si un element est présent après alors on le fusionne avec les nouvelles data
                itemAfter.start = item.start
            } else if(itemUnder){
                for (let index = 0; index < itemUnder.length; index++) {
                    let itemUnderIndex = this.genericUnavailable2.findIndex((e) => e.day == unavailableData.day && e.start == itemUnder[index].start && e.end == itemUnder[index].end)
                    this.genericUnavailable2.splice(itemUnderIndex, 1)
                }
                this.genericUnavailable2.push(item);
            } else { // sinon on le push
                this.genericUnavailable2.push(item);
            }
        },
        //Suppression d'une indispo
        removeUnavailable(event, slot){
            let dayItem = this.genericUnavailable2.findIndex((e) => e.day == slot.day && e.start == slot.start)
            //let slotIndex = dayItem.slots.findIndex((e) => e.start == slot.start)
            this.genericUnavailable2.splice(dayItem, 1)
        },
        removeAllUnavailable(){},
        addAllUnavailable(){},
    },
}
</script>