<template>
    <div>
    <v-dialog scrollable persistent width="1200" v-model="openTeamAttribution">
        <v-card color="lightgrey">
            <v-toolbar tile dark class="bg-gradient">
                <v-btn
                    depressed
                    color="white"
                    class="square mr-2"
                    text
                    @click.native="$emit('close')"
                >
                    <v-icon dark small>$prev</v-icon>
                </v-btn>
                <v-toolbar-title>{{$t('vendorPlanning')}}</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-btn depressed outlined class="mr-0" color="white" @click="$emit('close')">
                    {{$t('record')}}
                </v-btn>
            </v-toolbar>
            <v-card-text>
                <div class="mt-4 d-flex justify-space-between">
                    <v-select :items="entities" item-text="name"  item-value="name" v-model="entitySelected" solo  dense style="flex-grow:0;">
                    </v-select>
                    <v-btn depressed v-if="!$store.state.auth.user.is_presta && packId != 4"  @click="getForm('/operation/getFormUserPlanning?operation_id='+operationId)" class="mb-2 mr-0 bg-gradient">{{$t('addteamate')}}</v-btn>
                </div>
                <div>
                    <v-expansion-panels flat accordion>
                        <v-expansion-panel
                            v-for="(user,i) in subTeam[entitySelected]"
                            :key="i"
                            class="mb-2 panelUser"
                        >
                            <v-expansion-panel-header hide-actions>
                                <template v-slot:default="{open}">
                                    <div>
                                        <div class="d-flex align-center justify-space-between">
                                            <div class="d-flex align-center" style="width:70%;">
                                                <v-icon color="black" :class="open ? 'icon rotate mr-2' : 'icon mr-2'">
                                                $dropdown 
                                                </v-icon>
                                                <v-avatar size="40" class="ml-3">
                                                    <v-img :src="api+'/user/'+user.id+'/getAvatar?token='+$store.state.auth.idToken"></v-img>
                                                </v-avatar>
                                                <span class="ml-3">
                                                    <h4 class="mb-0 pb-0" style='text-decoration:none; border-bottom:0; text-transform:none; font-size:18px;'>{{user.firstname}} {{user.name}}</h4>
                                                    <p class="mt-1 mb-0">{{user.poste[0]}} {{$t('at')}} {{user.entity.name}}</p>
                                                </span>
                                                
                                            </div>
                                            <div style="width:30%;" class="d-flex align-center justify-space-between">
                                                <v-chip v-if="user.confObserver" color="info">{{$t('observer')}}</v-chip>
                                                <v-chip v-else-if="!user.confActive" color="error">{{$t('notParticipating')}}</v-chip>
                                                <v-chip v-else-if="user.confPlanning" color="success">{{$t('withplanning')}}</v-chip>
                                                <v-chip v-else color="grey" dark>{{$t('withoutplanning')}}</v-chip>
                                                <div>
                                                    <!-- <v-tooltip v-if="user.confPlanning" left>
                                                        <template v-slot:activator="{ on, attrs }">
                                                            <v-btn small text class="square ml-3" @click.stop="" v-bind="attrs" v-on="on">
                                                                <v-icon small color="primary">$calendartimes_d</v-icon>
                                                            </v-btn>
                                                        </template>
                                                        <span>{{$t("removeplanning")}}</span>
                                                    </v-tooltip> -->
                                                    <v-tooltip v-if="user.confPlanning && packId != 4" left>
                                                        <template v-slot:activator="{ on, attrs }">
                                                            <v-btn small text class="square ml-3" @click.stop="addIndispo(user.id, user.entity.id)" v-bind="attrs" v-on="on">
                                                                <v-icon small color="primary">$calendartimes_d</v-icon>
                                                            </v-btn>
                                                        </template>
                                                        <span>{{$t("addindispo")}}</span>
                                                    </v-tooltip>
                                                    <v-btn v-if="user.confActive" small text class="square ml-3" @click.stop="getForm('/operation/getFormUserPlanning?operation_id='+operationId+'&id='+user.id)">
                                                        <v-icon small color="primary">$pencil_d</v-icon>
                                                    </v-btn>
                                                    <v-tooltip left v-if="user.confActive && $func.hasRight('operation/update')">
                                                        <template v-slot:activator="{ on, attrs }">
                                                            <v-btn small text class="square ml-3" @click.stop="getForm('\/operation\/' + operationId + '\/checkBeforeDeactivateUser?user_id=' + user.id)" v-bind="attrs" v-on="on">
                                                                <v-icon small color="primary">$usercross_d</v-icon>
                                                            </v-btn>
                                                        </template>
                                                        <span>{{$t("deactivate")}}</span>
                                                    </v-tooltip>
                                                    <v-tooltip left v-else-if="!user.confObserver && $func.hasRight('operation/update')">
                                                        <template v-slot:activator="{ on, attrs }">
                                                            <v-btn small text class="square ml-3" @click.stop="activateUser(user.id)" v-bind="attrs" v-on="on">
                                                                <v-icon small color="primary">$usercheck_d</v-icon>
                                                            </v-btn>
                                                        </template>
                                                        <span>{{$t("activate")}}</span>
                                                    </v-tooltip>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </template>
                            </v-expansion-panel-header>
                            <v-expansion-panel-content>
                                <div class="d-flex align-center justify-space-between">
                                    <div style="width:70%;">
                                        <ul class="ml-9" style="list-style:none;">
                                            <li v-for="(phone, index) in user.phones" :key="'phone_'+index" class="mb-1" style="position:relative;">
                                                <v-icon x-small color="mediumgrey" style="position:absolute; left:-23px; top:3px;">$circle_s</v-icon>
                                                {{phone.number}}
                                                <v-chip x-small outlined color="primary">{{phone.type}}</v-chip>
                                            </li>
                                            <li style="position:relative;">
                                                <v-icon x-small color="mediumgrey" style="position:absolute; left:-23px; top:3px;">$circle_s</v-icon>
                                                {{user.email}}
                                            </li>
                                        </ul>
                                    </div>
                                    <div style="width:30%;">
                                        <ul style="list-style:none;" v-if="user.confPlanning">
                                            <li style="position:relative;">
                                                <v-icon x-small color="mediumgrey" style="position:absolute; left:-23px; top:3px;">$circle_s</v-icon>
                                                {{$t('priority')}} : {{$t(user.confPrio)}}
                                            </li>
                                            <li style="position:relative;" v-if="user.confPlaces && Array.isArray(user.confPlaces) && user.confPlaces.length">
                                                <v-icon x-small color="mediumgrey" style="position:absolute; left:-23px; top:3px;">$circle_s</v-icon>
                                                {{$t('availableat')}} : {{(user.confPlaces.join(','))}}
                                            </li>
                                            <li style="position:relative;">
                                                <v-icon x-small color="mediumgrey" style="position:absolute; left:-23px; top:3px;">$circle_s</v-icon>
                                                {{$t('acceptvisio')}} : {{(user.confVisio ? $t('yes') : $t('no'))}}
                                            </li>
                                            <li style="position:relative;">
                                                <v-icon x-small color="mediumgrey" style="position:absolute; left:-23px; top:3px;">$circle_s</v-icon>
                                                {{$t('annotation')}} : {{(user.confComment)}}
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </v-expansion-panel-content>
                        </v-expansion-panel>
                    </v-expansion-panels>
                </div>
            </v-card-text>
        </v-card>
        <FormDialogComponent
        v-if="form"
        ref="componentForm"
        :form="form"
        :action="'SET_DATA'"
        />
        <v-dialog v-model="dialogUserIndispo" fullscreen scrollable transition="dialog-bottom-transition">
            <v-card rounded="lg">
                <v-toolbar tile dark class="bg-gradient">
                    <v-btn
                        depressed
                        color="white"
                        class="square mr-2"
                        text
                        @click.native="closeRecordUserIndispo"
                    >
                        <v-icon dark small>$prev</v-icon>
                    </v-btn>
                    <v-toolbar-title>{{$t('userIndispoTitle')}}</v-toolbar-title>
                    <v-spacer></v-spacer>
                     <v-btn depressed outlined color="white" class="mr-3" @click="closeRecordUserIndispo">
                        {{$t('cancelLabel')}}
                    </v-btn>
                    <v-btn depressed outlined color="white" @click="recordUserIndispo()">
                        {{$t('recordRdvLabel')}}
                    </v-btn>
                </v-toolbar>
                <v-card-text class="pa-0">
                    <Planning ref="planning_indispo"
                    type="INDISPO_USER"
                    :genericUnavailable="userUnavailable"
                    :isMine="false"
                    :vendeurId="userIndispoId"
                    :entityId="entityIndispoId"
                    :creneau="60"
                    :operations="operations"
                    :operationId="operationId"
                    :appointements="[]"
                    @addItem="addItem"
                    @editItem="editItem"
                    @removeUnavailable="removeUnavailable"
                    @deleteUserIndispo="deleteUserIndispo"
                    @getPlanningIndispo="getUserIndispo"
                    mode="popup"
                    />
                </v-card-text>
            </v-card>
        </v-dialog>
    </v-dialog>
    <ConfirmDialogComponent ref="confirmDialog" />
    </div>
</template>
<script>

import GenericDataService from '@/services/GenericDataService'
import Loader from '@/components/Loader'
import getForm from "@/mixins/mixins";
import dateManipulation from '@/mixins/dateManipulation'
import FormDialogComponent from '@/components/dialogs/FormDialogComponent'
import Planning from '@/components/planning/Planning'
import ConfirmDialogComponent from '@/components/dialogs/ConfirmDialogComponent';

export default {
    name: "teamAttributionPopup",
    props: ['operation', 'operationId', 'openTeamAttribution'],
    mixins:[getForm, dateManipulation],
    components: {Loader, FormDialogComponent, Planning, ConfirmDialogComponent},
    data() {
        return {
            api: this.$store.state.auth.apiUrl,
            team: [],
            subTeam: {},
            entities: [],
            entitySelected: null,
            loading:false,
            form: null,
            dialogUserIndispo:false,
            userIndispoId: 0,
            entityIndispoId: 0,
            userUnavailable: [],
            toSave: [] ,
            operations: [],
            userStartDate: "",
            userNbDays:7

        }
    },
    created() {
        this.refreshTeam()
    },
    watch: {
        operationId(){
            this.refreshTeam()
        }
    },
    methods: {
        refreshTeam(){
            this.loading = true
            GenericDataService.getData('/operation/'+this.operationId+'/getTeamActive?withDeactivate=true').then((response) => {
                this.team = response.data.data
                let data = [] 
                this.entities = []
                this.team.forEach((user) => {
                    if(!data.includes(user.entity.id)){
                        data.push(user.entity.id)
                        this.entities.push(user.entity)
                    }
                   
                })
                
                this.entities.forEach(entity => {
                    let entityName = entity.name
                    this.subTeam[entityName] = this._.filter(this.team, function(e) { return e.entity_id == entity.id; });
                });
                if(this.operation && this.operation['config.entity_id']){
                    let entitiesOpe = this.operation['config.entity_id'];
                    entitiesOpe.forEach(element => {
                        if(!this.subTeam[element.name]){
                            this.subTeam[element.name] = []
                        }
                    });
                }
                
                if(this.entities.length >= 1)
                    this.entitySelected = this.entities[0].name
                this.loading = false
            })
        },
        addIndispo(userId, entityId){
            GenericDataService.getData('/operation/'+this.operationId+'/get').then((response) => {
                this.operations.push(response.data.data)
                this.userIndispoId = userId
                this.entityIndispoId = entityId
                setTimeout(() => {
                    this.dialogUserIndispo = true
                }, 25);
            })
            
            
        },
        customRefresh(){
            this.refreshTeam()
        },
        recordUserIndispo() {
            GenericDataService.postData('/user/'+this.userIndispoId+'/setHours', this.toSave).then((response) => {
                
                let payload = {
                    start: this.planningStartDate,
                    nbdays: this.planningNbDays
                }

                if(this.operation.type == "MARKETING"){
                    payload.entity_id = this.entityIndispoId
                } else {
                    payload.operation_id = this.operationId
                }

                GenericDataService.postData('/user/'+this.userIndispoId+'/getHours', payload).then((response) => {
                    this.closeRecordUserIndispo();
                })
            })
        },
        deleteUserIndispo(slot){
            if(slot.id){
                slot.type = "delete"
                this.toSave.push(slot)
            } else {
                let itemToDelete = this.toSave.find((e) => (e.day == slot.day &&  e.start == slot.start))
                itemToDelete.type = "delete"
            }
            let dayItem = this.userUnavailable.findIndex((e) => (e.day == slot.day &&  e.start == slot.start))
            this.userUnavailable.splice(dayItem, 1)
        },
        getUserIndispo(payload){
            
            if(this.userIndispoId != 0 || this.entityIndispoId != 0){
                this.userStartDate = payload.start
                this.userNbDays = payload.nbdays
                let payload2 = {
                    start: payload.from,
                    nbdays: payload.nbdays
                }

                if(this.operation.type == "MARKETING"){
                    payload2.entity_id = this.entityIndispoId
                } else {
                    payload2.operation_id = this.operationId
                }

                GenericDataService.postData('/user/'+this.userIndispoId+'/getHours', payload2).then((response) => {
                    this.userUnavailable = response.data.data.slots.concat(this.toSave)
                })
            }   
            
        },
        editItem(event, itemData){
            let itemIndex = this.userUnavailable.findIndex((e) => e.start == itemData.start && e.end == itemData.end && e.id == itemData.id)
            this.userUnavailable.splice(itemIndex, 1)
            this.userUnavailable.push(itemData)

            let toSaveIndex = this.toSave.findIndex((e) => e.start == itemData.start && e.end == itemData.end && e.id == itemData.id)
            this.toSave.splice(toSaveIndex, 1)
            this.toSave.push(itemData)
        },
        //Ajout d'une indispo utilisateur
        addItem:function(event, unavailableData) {
            let slot = this.userUnavailable.find((e) => e.date == unavailableData.date)
            let item = this._.cloneDeep(unavailableData);
            //check si une indispo est présente avant et apres
            let itemBefore = this.userUnavailable.find((e) => e.date == unavailableData.date && item.source == e.source && e.type == item.type &&
                (e.end == item.start ||
                (this.parseHourToFloat(e.start) < this.parseHourToFloat(item.start)
                && this.parseHourToFloat(item.start) <= this.parseHourToFloat(e.end)
                && this.parseHourToFloat(item.end) > this.parseHourToFloat(e.end))
                )
            );
            let itemAfter = this.userUnavailable.find((e) => e.date == unavailableData.date && item.source == e.source && e.type == item.type &&
                (e.start == item.end ||
                    (this.parseHourToFloat(item.start) < this.parseHourToFloat(e.start)
                    && this.parseHourToFloat(item.end) >= this.parseHourToFloat(e.start)
                    && this.parseHourToFloat(e.end) > this.parseHourToFloat(item.end))
                )
            );
            let itemUnder = this.userUnavailable.filter((e) => e.date == unavailableData.date && this.parseHourToFloat(e.start) > this.parseHourToFloat(item.start) && this.parseHourToFloat(e.end) < this.parseHourToFloat(item.end) && e.end != "24:00" && item.source == e.source && e.type == item.type && e.value == item.value)

            // //si les deux existent on redéfini les horaires du premier et on supprime le deuxieme
            if(itemBefore && itemAfter){
                let itemAfterIndex = this.userUnavailable.findIndex((e) => e.date == unavailableData.date && e.start == item.end && item.source == e.source && e.type == item.type);
                itemBefore.end = itemAfter.end
                if(itemBefore.id){
                    this.toSave.push(itemBefore)
                }
                if(itemAfter.id) {
                    itemAfter.type = "delete"
                    this.toSave.push(itemAfter)
                } else {
                    let itemAfterToSaveIndex = this.toSave.findIndex((e) => e.date == unavailableData.date && e.start == item.end && item.source == e.source && e.type == item.type);
                    this.toSave.splice(itemAfterToSaveIndex, 1)
                }
                this.userUnavailable.splice(itemAfterIndex, 1)
            }else if(itemBefore){   // si un element est présent avant alors on le fusionne avec les nouvelles data
                //let itemBeforeIndex = this.toSave
                itemBefore.end = item.end
                if(itemBefore.id){
                    this.toSave.push(itemBefore)
                }

            } else if (itemAfter) { // si un element est présent après alors on le fusionne avec les nouvelles data
                itemAfter.start = item.start
                if(itemAfter.id){
                    this.toSave.push(itemAfter)
                }
            } else if(itemUnder){
                for (let index = 0; index < itemUnder.length; index++){
                    let itemUnderIndex = this.userUnavailable.findIndex((e) => e.date == unavailableData.date && e.start == itemUnder[index].start && e.end == itemUnder[index].end && itemUnder[index].source == e.source && e.type == itemUnder[index].type && e.value == itemUnder[index].value)
                    if(itemUnder[index].id){
                        itemUnder[index].type = "delete"
                        this.toSave.push(itemUnder[index])
                    } else{
                        let toSaveItemUnderIndex = this.toSave.findIndex((e) => e.date == unavailableData.date && e.start == itemUnder[index].start && e.end == itemUnder[index].end && itemUnder[index].source == e.source && e.type == itemUnder[index].type && e.value == itemUnder[index].value)
                        this.toSave.splice(toSaveItemUnderIndex, 1)
                    }
                    this.userUnavailable.splice(itemUnderIndex, 1)
                }
                this.userUnavailable.push(item);
                this.toSave.push(item)
            } else { // sinon on le push
                this.userUnavailable.push(item);
                this.toSave.push(item)
            }
        },
        //Suppression d'une indispo -------------------------------------------------------------- TOBEREDONE
        removeUnavailable(event, slot){
            let dayItem = this.genericUnavailable.find((e) => e.day == slot.day)
            let slotIndex = dayItem.slots.findIndex((e) => e.start == slot.start)
            dayItem.slots.splice(slotIndex, 1)
        },
        closeRecordUserIndispo() {
            this.toSave = []
            this.userIndispoId=0;
            this.entityIndispoId = 0;
            this.dialogUserIndispo = false

        },
        activateUser(user_id) {
            let link = {
                action: "activate",
                confirm: {
                    label: "userActivateConfirmTitle",
                    message: "userActivateConfirmMessage"
                },
                url: "/operation/" + this.operationId + "/activateUser?user_id=" + user_id,
            };
            let options = { action: "/operation/" + this.operationId + "/activateUser?user_id=" + user_id };

            this.openConfirmModal(link, options)
        },
        async openConfirmModal(link, options) {
            if(await this.$refs.confirmDialog.open(this.$t(link.confirm.label), this.$t(link.confirm.message), options)) {
                this.customRefresh();
                if(options.action) {
                    this.$store.dispatch('ADD_SNACK', {
                        message : this.$t('successActionConfirmed'),
                    }).then((e) => {
                        this.$store.dispatch("REMOVE_SNACK")
                    });
                }
            }
        },
    },
    computed: {
        packId(){
            if(this.$store.state.auth.currentUser.accountParameters.packId)
                return this.$store.state.auth.currentUser.accountParameters.packId
            else 
                return false
        },
    }
}
</script>